body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

#root {
  overflow-x: hidden;
}


/* .scroll-snap {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
 } */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #8C7E8D;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #8C7E8D;
}

.scroll-box {
  overflow-y: scroll;
  height: 200px;
  border: 1px solid #ccc;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.currencyFont {
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
}

.flex {
  display: flex
}