@font-face {
  font-family: "ApfelGrotezkRegular";
  src: url("./assets/fonts/ApfelGrotezk-Regular.woff2") format("woff2"),
       url("./assets/fonts/ApfelGrotezk-Regular.woff") format("woff");
}

@font-face {
  font-family: "ApfelGrotezkFett";
  src: url("./assets/fonts/ApfelGrotezk-Fett.woff2") format("woff2"),
       url("./assets/fonts/ApfelGrotezk-Fett.woff") format("woff");
}

@font-face {
  font-family: "ApfelGrotezkBrukt";
  src: url("./assets/fonts/ApfelGrotezk-Brukt.woff2") format("woff2"),
       url("./assets/fonts/ApfelGrotezk-Brukt.woff") format("woff");
}

@font-face {
  font-family: "objectivitymedium";
  src: url("./assets/fonts/objectivity-medium-webfont.woff2") format("woff2"),
       url("./assets/fonts/objectivity-medium-webfont.woff") format("woff");
}

@font-face {
  font-family: "objectivityregular";
  src: url("./assets/fonts/objectivity-regular-webfont.woff2") format("woff2"),
       url("./assets/fonts/objectivity-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: 'Work Sans';
  src: url("./assets/fonts/WorkSans-Regular.woff2") format('woff2'),
      url("./assets/fonts/WorkSans-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Work Sans';
  src: url("./assets/fonts/WorkSans-Medium.woff2") format('woff2'),
      url("./assets/fonts/WorkSans-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
